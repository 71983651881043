import axios from "axios";
import Config from "@/utils/conf";
import i18n from "@/utils/i18n_lang";

export default {
  request: function() {
    const headers = {
      "Content-Type": "application/json",
      "x-api-key": Config.getApiKey(),
      "x-language": i18n.locale
    };
    // Set config defaults when creating the instance
    const instance = axios.create({
      baseURL: Config.getBaseURL(),
      headers: headers
    });
    return instance;
  },
  requestFile: function() {
    const headers = {
      "x-api-key": Config.getApiKey(),
      "x-language": i18n.locale,
      "Content-Type": "application/x-www-form-urlencoded"
    };
    // Set config defaults when creating the instance
    const instance = axios.create({
      baseURL: Config.getBaseURL(),
      headers: headers
    });
    return instance;
  },
  errorMessage: function(error) {
    switch (error.response.status) {
      case 401:
        break;

      default:
    }
    return error.response;
  }
};
